<template>
  <div id="app">
    <!-- <Hearder></Hearder> -->
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Hearder from "@/components/Header.vue";
export default {
  components: {
    Hearder,
    Footer
  }
};
</script>

<style lang="less">
*::-webkit-scrollbar {
  display: none;
}
* {
  margin: 0;
  padding: 0;
}
</style>
