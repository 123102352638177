<template>
  <div>
    <!-- PC端显示 -->
    <div class="PC_Show">
      <!-- 第一部分 轮播图-->
      <div>
        <div class="show-imgs-container">
          <el-carousel height="56.25vw" arrow="always">
            <el-carousel-item v-for="(item,index) in videos" :key="index">
              <div class="show-imgs">
                <img :src="item.poster" alt />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 公司简介 -->
      <div class="slid2">
        <div class="head">
          <div class="left">
            <img src="../assets/logo.png" class="logo" />
            <div class="title">深圳新能智慧充电科技有限公司</div>
          </div>
          <div class="right">
            <ul>
              <li>目的地</li>
              <li>有序充电</li>
            </ul>
          </div>
        </div>
        <div class="content">
          <p>深圳新能智慧充电科技有限公司成立于2023年，公司研发中心位于深圳市南山区，团队核心骨干深耕电力电子领域二十余年，具有IT和电力电子双重从业经验。</p>
          <p>成立之前，准确预判了目的地充电趋势，紧紧围绕趋势，自主研发了充电运营SaaS平台、有序整桩方案、能源路由器等核心产品，是国内第一家实现并落地有序充电方案的公司，同时也是国内第一家能实现低成本全覆盖的解决方案商。</p>
          <p>在不增容的情况下通过安装配套的有序充电产品，即可实现智能调度、有序充电、安全用电，不论是对老旧小区的改造还是新建小区、写字楼以及商场停车场的充电站场建设都具有意义深远的影响。新能慧充将持续对产品进行升级优化，力争为新型绿色电力系统提供建设支持进一步提升社区场景下对电力供给端和需求端的调和能力，实现更灵活、更智慧更多元的电力资源管理模式，同时也积极推动“碳达峰、碳中和”从理念到实际的应用发展。</p>
        </div>
      </div>
      <!-- 目的地充电 -->
      <div class="slide3">
        <div class="de-title">目的地充电</div>
        <div class="de-subTitle">DESTINATION</div>
        <div class="De-content">
          <div class="member-wrap">
            <div class="de-member">
              <div class="de-img de-img1"></div>
              <div class="de-mem-sub">
                国家政策引导+新能源汽车爆发式增长+充电桩缺口巨大+车主时间成本导向，充电桩建设转向以社区小区、办公写字楼等业态为主的
                <span
                  class="main-title"
                >"目的地充电"</span>发展。
              </div>
            </div>
            <div class="de-member">
              <div class="de-img de-img2"></div>
              <div class="de-mem-sub">
                目的地充电是未来电动车充电的主要场景:
                <span class="main-title">便利、优惠、闲置时间充电</span> 。
                如居民小区、酒店、商超、医院、写字楼、政务中心及景区等电动车较长时间停留的场景。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="charge">
        <div class="partTwo">
          <h2 class="Title">有序充电</h2>
          <div class="subTitle">ORDER-CHARGING</div>
          <div class="content">
            <div class="contentLeft">
              <img src="../assets/orderCharge/pile.jpg" alt />
            </div>
            <div class="contentRight">
              <div class="advantage">
                <div class="title">有序充电的优势</div>
                <ul>
                  <li>
                    <span class="left">①：电网减负</span>
                  </li>
                  <li>
                    <span class="left">②：低成本、全覆盖</span>
                  </li>
                  <li>
                    <span class="left">③：配电安全</span>
                  </li>
                </ul>
              </div>
              <div class="feature">
                <div class="title">系统特点</div>
                <ul>
                  <li>
                    <span class="left">①：实时、智能启停充电桩</span>
                  </li>
                  <li>
                    <span class="left">②：优先保证居民用电</span>
                  </li>
                  <li>
                    <span class="left">③：变压器永不过载</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 侧边部分 -->
      <ul class="wrapper aside">
        <li @click="toTop">
          <div class="bg-item" style="opacity: 1;">
            <img
              src="https://resource.teld.cn/teldimage/107/teld-official-index-icon-top.png"
              alt
              class="top-img"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import "swiper/css/swiper.min.css";

export default {
  props: {
    title: {
      //模块标题
      type: String,
      default: ""
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      videos: [
        {
          poster: require("@/assets/homeImg/新能充电.jpg")
        },
        {
          poster: require("@/assets/homeImg/有序充电.jpg")
        },
        {
          poster: require("@/assets/homeImg/34.jpg")
        }
      ],
      swiper: null
    };
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>
<style lang="less" scoped>
// PC端显示
.PC_Show {
  overflow: hidden;
  .swiper-slide {
    width: 100%;
    overflow: hidden;
    height: 100vh;

    .slide-wrap {
      position: relative;
      height: 100%;
      background-size: cover;

      .content {
        position: relative;
        height: 100%;
        background-size: cover;
        background-size: 100% 100%;
      }
    }
  }
  // 第一部分
  .show-imgs-container {
    height: 56.25vw;

    .el-carousel {
      width: 100%;
      height: 56.25vw;
    }

    .el-carousel__item {
      width: 100%;
      height: 56.25vw;
      img {
        width: 100%;
        height: 56.25vw;
        position: fixed;
        display: inline-block;
        vertical-align: baseline;
        object-fit: fill; //消除留白
      }
    }

    ::v-deep .el-carousel__arrow {
      border: none;
      outline: 0;
      padding: 0;
      margin: 0;
      height: 36px;
      width: 36px;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 50%;
      background-color: rgba(31, 45, 61, 0.11);
      color: #fff;
      position: absolute;
      // top: 150%;
      z-index: 10;
      // transform: translateY(-100%);
      text-align: center;
      font-size: 12px;
    }
  }
  // 第二部分 公司简介
  .slid2 {
    padding: 0 150px;
    margin-bottom: 80px;
    .head {
      display: flex;
      align-content: center;
      justify-content: space-between;
      height: 124px;
      .left {
        display: flex;
        align-items: center;
        .logo {
          height: 80px;
        }
        .title {
          width: 480px;
          line-height: 124px;
          text-indent: 50px;
          font-weight: 500;
          font-size: 28px;
        }
      }
      .right {
        ul {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: space-between;
          align-items: center;
          li {
            width: 100px;
            height: 124px;
            line-height: 124px;
          }
        }
      }
    }
    .content {
      line-height: 35px;
      font-size: 20px;
      color: #333333;
    }
  }
  @media (max-width: 1800px) {
    .slid2 {
      .content {
        line-height: 30px;
      }
    }
  }
  @media (max-width: 1600px) {
    .slid2 {
      .content {
        line-height: 25px;
      }
    }
  }
  @media (max-width: 1200px) {
    .slid2 {
      .head {
        height: 104px;
        .left {
          .logo {
            height: 60px;
          }
          .title {
            width: 380px;
            line-height: 104px;
            text-indent: 20px;
            font-weight: 500;
            font-size: 24px;
          }
        }
      }
      .content {
        line-height: 25px;
        font-size: 18px;
        color: #333333;
      }
    }
  }
  @media (max-width: 768px) {
    .slid2 {
      padding: 0 30px;
      margin-bottom: 20px;
      .head {
        display: flex;
        align-content: center;
        justify-content: space-between;
        height: 70px;
        .left {
          display: flex;
          align-items: center;
          .logo {
            height: 30px;
          }
          .title {
            width: 300px;
            line-height: 70px;
            text-indent: 10px;
            font-weight: 500;
            font-size: 18px;
          }
        }
        .right {
          display: none;
        }
      }
      .content {
        line-height: 20px;
        font-size: 12px;
        color: #333333;
      }
    }
  }

  // 目的地充电
  .slide3 {
    padding: 0 150px;
    .de-title {
      margin-top: 16px;
      line-height: 35px;
      font-weight: 400;
      font-style: normal;
      font-size: 28px;
      letter-spacing: normal;
      color: #333333;
      text-align: left;
      text-transform: none;
    }
    .de-subTitle {
      margin-top: 16px;
      line-height: 35px;
      font-family: "Arial Normal", "Arial";
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      color: #333333;
      text-align: left;
      text-transform: none;
      padding-top: 0;
      font-size: 20px;
      margin-bottom: 16px;
    }
    .De-content {
      .member-wrap {
        width: 100%;
        display: flex;
        .de-member:nth-child(odd) {
          margin-right: 4%;
        }
        .de-member {
          // float: left;

          width: 48%;
          margin-bottom: 4%;
          .de-img {
            width: 100%;
            padding-bottom: 66%;
            height: 0;
          }
          .de-img1 {
            background: url(../assets/pile/6.jpg);
            background-size: cover;
          }
          .de-img2 {
            background: url(../assets/pile/add.jpg);
            background-size: cover;
          }
          .de-mem-sub {
            margin-top: 15px;
            .main-title {
              color: #069559;
              font-size: 21px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1400px) {
    .slide3 {
      padding: 0 150px;
      .de-title {
        margin-top: 16px;
        line-height: 35px;
        font-weight: 400;
        font-style: normal;
        font-size: 28px;
        letter-spacing: normal;
        color: #333333;
        text-align: left;
        text-transform: none;
      }
      .de-subTitle {
        margin-top: 16px;
        line-height: 35px;
        font-family: "Arial Normal", "Arial";
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        color: #333333;
        text-align: left;
        text-transform: none;
        padding-top: 0;
        font-size: 20px;
        margin-bottom: 16px;
      }
      .De-content {
        .member-wrap {
          width: 100%;
          display: flex;
          .de-member:nth-child(odd) {
            margin-right: 4%;
          }
          .de-member {
            width: 48%;
            margin-bottom: 4%;
            .de-img {
              width: 100%;
              padding-bottom: 66%;
              height: 0;
            }
            .de-img1 {
              background: url(../assets/pile/6.jpg);
              background-size: cover;
            }
            .de-img2 {
              background: url(../assets/pile/add.jpg);
              background-size: cover;
            }
            .de-mem-sub {
              margin-top: 15px;
              .main-title {
                color: #069559;
                font-size: 18px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .slide3 {
      padding: 0 30px;
      .de-title {
        margin-top: 10px;
        line-height: 25px;
        font-size: 22px;
      }
      .de-subTitle {
        margin-top: 10px;
        line-height: 20px;
        padding-top: 0;
        font-size: 16px;
        margin-bottom: 16px;
      }
      .De-content {
        .member-wrap {
          width: 100%;
          display: flex;
          .de-member:nth-child(odd) {
            margin-right: 4%;
          }
          .de-member {
            .de-mem-sub {
              margin-top: 15px;
              font-size: 12px;
              .main-title {
                color: #069559;
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
  // 有序充电
  .charge {
    padding: 0 150px;
    .partTwo {
      height: auto;
      .Title {
        margin-top: 16px;
        line-height: 35px;
        font-weight: 400;
        font-style: normal;
        font-size: 28px;
        letter-spacing: normal;
        color: #333333;
        text-align: left;
        text-transform: none;
      }
      .subTitle {
        margin-top: 16px;
        line-height: 35px;
        font-family: "Arial Normal", "Arial";
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        color: #333333;
        text-align: left;
        text-transform: none;
        padding-top: 0;
        font-size: 20px;
        margin-bottom: 16px;
      }
      .content {
        display: flex;
        align-items: center;
        margin: 30px 0;
        .contentLeft {
          flex: 1;
          height: 100%;
          img {
            width: 100%;
            height: 350px;
            object-fit: cover;
            object-position: top;
          }
        }
        .contentRight {
          flex: 1;
          background-color: #f6f6f6;
          .advantage {
            border-bottom: 1px solid #ccc;
            margin: 10px;
            padding: 0 10px 10px 10px;
            .title {
              margin-bottom: 5px;
              font-size: 18px;
            }
            ul li {
              list-style: none;
            }
            li {
              // color: #a8a6a6;
              margin-bottom: 5px;
              font-size: 16px;
              color: #797575;
              line-height: 35px;
            }
          }
          .feature {
            margin: 10px;
            padding: 0 10px 10px 10px;
            .title {
              font-size: 18px;
              margin-bottom: 5px;
            }
            ul li {
              list-style: none;
            }
            li {
              margin-bottom: 5px;
              font-size: 16px;
              color: #797575;
              line-height: 35px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1700px) {
    .charge {
      .partTwo {
        height: auto;
        .content {
          .contentLeft {
            flex: 1;
            height: 100%;
            img {
              width: 100%;
              height: 300px;
              object-fit: cover;
              object-position: top;
            }
          }
          .contentRight {
            flex: 1;
            .advantage {
              ul li {
                list-style: none;
              }
              li {
                line-height: 25px;
              }
            }
            .feature {
              li {
                line-height: 25px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1400px) {
    .charge {
      padding: 0 150px;
      .partTwo {
        height: auto;
        .content {
          display: flex;
          align-items: center;
          margin: 30px 0;
          .contentLeft {
            flex: 1;
            height: 100%;
            img {
              width: 100%;
              height: 260px;
              object-fit: cover;
              object-position: top;
            }
          }

          .contentRight {
            flex: 1;
            background-color: #f6f6f6;
            .advantage {
              li {
                // color: #a8a6a6;
                margin-bottom: 5px;
                font-size: 16px;
                color: #797575;
                line-height: 20px;
              }
            }
            .feature {
              li {
                margin-bottom: 5px;
                font-size: 16px;
                color: #797575;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .charge {
      padding: 0 30px;
      .partTwo {
        .Title {
          margin-top: 10px;
          line-height: 20px;
          font-size: 22px;
        }
        .subTitle {
          margin-top: 10px;
          line-height: 10px;
          padding-top: 0;
          font-size: 16px;
          margin-bottom: 10px;
        }
        .content {
          display: block;
          align-items: center;
          margin: 15px 0;
          .contentLeft {
            height: 100%;
            img {
              width: 100%;
              height: 350px;
              object-fit: cover;
              object-position: top;
            }
          }
          .contentRight {
            background-color: #f6f6f6;
            .advantage {
              border-bottom: 1px solid #ccc;
              margin: 10px;
              padding: 0 10px 10px 10px;
              .title {
                margin-bottom: 5px;
                font-size: 14px;
              }
              li {
                // color: #a8a6a6;
                margin-bottom: 5px;
                font-size: 12px;
                color: #797575;
                line-height: 20px;
              }
            }
            .feature {
              margin: 10px;
              padding: 0 10px 10px 10px;
              .title {
                font-size: 14px;
                margin-bottom: 5px;
              }
              ul li {
                list-style: none;
              }
              li {
                margin-bottom: 5px;
                font-size: 12px;
                color: #797575;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
  // 侧边部分
  .aside {
    z-index: 2;
    position: fixed;
    right: 50px;
    bottom: 45px;

    li {
      .bg-item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: rgba(0, 83, 131, 0.25);
        margin-bottom: 17px;
        cursor: pointer;
        .top-img {
          width: 65%;
        }
        .aside-icon {
          width: 50%;
        }
        .left-img-wrap {
          position: absolute;
          display: none;
          left: -125px;
          top: -50%;
          width: 140px;
          height: 110px;
          .left-img {
            width: 110px;
            height: 110px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .aside {
      display: none;
    }
  }

  li,
  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
</style>